import './H_Line.css';

function H_Line({ className }) {
    let classes = className ? "H_Line " + className : "H_Line";
    return (
        <div className={classes}></div>
    )
}

export default H_Line;
