import './Plan_Page.css';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Plan_Form from '../../Components/Plan/Plan_Form';
import V_Spacer from '../../Helpers/Spacer/V_Spacer';
import React from 'react';

function Plan_Page() {
  return (
    <div className='Plan_Page'>
        <Header />
        <V_Spacer /><V_Spacer />
        <div className='Plan_Page_content'>
            <Plan_Form />
        </div>
        <V_Spacer /><V_Spacer />
        <Footer />
    </div>
  );
}

export default Plan_Page;
