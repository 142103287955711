import './App.css';
import SignUp_Page from './Pages/SignUp_Page/SignUp_Page';
import Route from './Helpers/Route';
import Login_Page from './Pages/Login_Page/Login_Page';
import Plan_Page from './Pages/Plan_Page/Plan_Page';
import ConfirmEmail_Page from './Pages/ConfirmEmail_Page/ConfirmEmail_Page';
import Home_Page from './Pages/Home_Page/Home_Page';
import Dashboard from './Dashboards/DashboardDemo/Dashboard';
import InventoryManager from './Dashboards/InventoryManager/InventoryManager';

function App() {

  return (
    <div className="App">
      <Route path="/signup/">
        <SignUp_Page />
      </Route>
      <Route path="/login/">
        <Login_Page />
      </Route>
      <Route path="/confirm-email/">
        <ConfirmEmail_Page />
      </Route>
      <Route path="/">
        <Home_Page />
      </Route>
      <Route path="/plan/">
        <Plan_Page />
      </Route>

      <Route path="/inventory_manager/">
        <InventoryManager />
      </Route>
      <Route path="/dashboard_demo/">
        <Dashboard />
      </Route>
    </div>
  );
}

export default App;
