import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";


const firebaseConfig = {
  apiKey: "AIzaSyDr8R5XTshGgVj1H-yY0GnpszycbiQkq7o",
  authDomain: "quantum-maintenance.firebaseapp.com",
  projectId: "quantum-maintenance",
  storageBucket: "quantum-maintenance.appspot.com",
  messagingSenderId: "3629054849",
  appId: "1:3629054849:web:2d36932ec7539bec650680",
  measurementId: "G-ZS1X9SY42T"
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
