class Utils {

    static validMail(mail)
    {
        return /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(mail);
    }

    static validMobile(mobileNumber) {
        return /^\+?[0-9 ]+$/.test(mobileNumber);
    }

    static currentTime() {
        return (new Date()).getTime() / 1000;
    }

    static getParams(value=null) {
        if(value===null) { value = window.location.href; }
        var urlParams = new URL(value).searchParams
        return Array.from(urlParams.keys()).reduce((acc, key) => {
          acc[key] = urlParams.get(key)
          return acc
        }, {})
    }
    
    static expandPath(path) {
        if(!path.endsWith("/")) {
            return path + "/";
        }
        return path;
    }
}

export default Utils;
