import Dialog from '@mui/material/Dialog';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';


export function Login_Processing_Dialog({ open }) {
    return (
        <Dialog open={open}>
            <Alert severity="info">
                <AlertTitle>Logging In</AlertTitle>
            </Alert>
            <Grid container p={5} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>

        </Dialog>
    );
}

export function Login_Error_Dialog({ open, error, onClose }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <Grid container p={5} justifyContent="center" alignItems="center"
                    direction="column" p={0}>
                    <Typography variant="body1">{error}</Typography>
                    <Box mt={3}>
                        <Button variant="contained" onClick={onClose}>Close</Button>
                    </Box>
                </Grid>
            </Alert>
        </Dialog>
    );
}

