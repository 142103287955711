import './ConfirmEmail_Page.css';
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Link from '../../Helpers/Text/Link';
import Utils from '../../Utils';
import config from '../../config.json';

import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import AlertTitle from "@mui/material/AlertTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState, useEffect } from 'react';

import * as Realm from 'realm-web';
let realmApp = new Realm.App({ id:  config.Realm.APP_ID});


function Processing({ open }) {
    return (
        <Dialog open={open}>
            <Alert severity="info">
                <AlertTitle>Confirming Your Account</AlertTitle>
            </Alert>
            <Grid container p={5} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        </Dialog>
    );
}

function Error({ open, error }) {
    return (
        <Dialog open={open}>
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <Grid container p={5} justifyContent="center" alignItems="center"
                    direction="column" p={0}>
                    <Typography variant="body1">{error}</Typography>
                </Grid>
            </Alert>
        </Dialog>
    );
}

function Info({ open, info }) {
    return (
        <Dialog open={open}>
            <Alert severity="warning">
                <AlertTitle>Information</AlertTitle>
                <Grid container p={5} justifyContent="center" alignItems="center"
                    direction="column" p={0}>
                    <Typography variant="body1">{info}</Typography>
                </Grid>
            </Alert>
        </Dialog>
    );
}

function Success({ open }) {
    return (
        <Dialog open={open}>
            <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                <Grid container p={5} justifyContent="center" alignItems="center"
                    direction="column" p={0}>
                    <Typography variant="body1">Verification was Successful</Typography>
                    <Box mt={3}>
                        <Link href="/login"><Button variant="contained">Continue to Login</Button></Link>
                    </Box>
                </Grid>
            </Alert>
        </Dialog>
    );
}

function ConfirmEmail_Page() {
    /*
        status values = [IDLE, LOADING, SUCCESS, ERROR, INFO]
    */
    let [ status, setStatus ] = useState("LOADING");
    let [ error, setError ] = useState(null);
    let [ info, setInfo ] = useState(null);
    useEffect(() => {
        let params = Utils.getParams();
        if("token" in params && "tokenId" in params) {
            let token = params.token;
            let tokenId = params.tokenId;
            console.log("token: ", token);
            console.log("tokenId: ", tokenId);
            console.log(params)
            realmApp.emailPasswordAuth.confirmUser({ token, tokenId })
            .then(() => setStatus("SUCCESS"))
            .catch(err => {
                if (err instanceof Realm.MongoDBRealmError) {
                    const { error, statusCode } = err;
                    const errorType = error || statusCode;
                    switch (errorType) {
                        case "userpass token is expired or invalid":
                            setError("Link Expired or Invalid");
                            setStatus("ERROR");
                            break;
                        default:
                            console.log(errorType);
                            setError("Error occurred while Verifying Account");
                            setStatus("ERROR");
                    }
                } else {
                    console.log(err);
                    setError("Error occurred while Verifying Account");
                    setStatus("ERROR");
                }
            });
        } else {
            setError("Unable to Verifiy Account!");
            setStatus("ERROR");
        }
    }, []);

    return (
        <div className="ConfirmEmail_Page">
            <Header />
            <div className="ConfirmEmail_Page_content">
                <Processing open={status==="LOADING"} />
                <Error open={status==="ERROR"} error={error} />
                <Info open={status==="INFO"} info={info} />
                <Success open={status==="SUCCESS"} />
            </div>
            <Footer />
        </div>
    )
}

export default ConfirmEmail_Page;
