import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Alert from '@mui/material/Alert';
import AlertTitle from "@mui/material/AlertTitle";
import Box from '@mui/material/Box';

import React from "react";
import { useState, useEffect } from 'react';
import Utils from "../../Utils";

const WAIT_TIME = 60;

function currentTime() {
    return Utils.currentTime();
}

function getTimeLeft(lastSentOn) {
    let t = parseInt(currentTime() - lastSentOn);
    t = WAIT_TIME - t;
    if(t < 0) {
        return 0;
    }
    return t;
}

/*
    This Dialog is used on -
        Success Sending Verfication Link
*/
export function SignUp_Success_Dialog({ open, onClose, email, resendConfirmation, sentOn }) {
    const [timeLeft, setTimeLeft] = useState(getTimeLeft(sentOn));
    useEffect(() => {
        const intervalId = setInterval(() => {
          setTimeLeft(t => t-1 < 0 ? 0 : t-1);
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    const onCloseCB = (event, reason) => {
        // Don't close, even if any area outside the popup is clicked!
        if(reason && reason == "backdropClick") {
            return;
        }
        onClose();
    }

    const resendEmail = () => {
        resendConfirmation(ct => {
            setTimeLeft(getTimeLeft(ct));
        });
    }

    return (
        <Dialog open={open} onClose={onCloseCB} >
            <DialogTitle>Confirm Email</DialogTitle>
            <Grid container px={8} direction="column">
                <Grid item pt={3}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Confirmation Link has been sent to your email:
                    </Typography>
                    <Typography variant="body1" px={4} py={1}>{email}</Typography>
                </Grid>
                <Grid item my={3}>
                    <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            <Button variant="contained" disabled={timeLeft > 0} onClick={resendEmail}>
                                Resend Email {timeLeft > 0 ? "in " + timeLeft + " s" : ""}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" onClick={onClose}>Change Email</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}


export function SignUp_Processing_Dialog({ open, email }) {
    return (
        <Dialog open={open}>
            <Alert severity="info">
                <AlertTitle>Sending Confirmation Link</AlertTitle>
                To Email: {email}
            </Alert>
            <Grid container p={5} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>

        </Dialog>
    );
}


export function SignUp_Error_Dialog({ open, onClose, error }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <Alert severity="error">
                <AlertTitle>Error Occured</AlertTitle>
                <Grid container p={5} justifyContent="center" alignItems="center"
                    direction="column" p={0}>
                    <Typography variant="body1">{error}</Typography>
                    <Box mt={3}>
                        <Button variant="contained" onClick={onClose}>Close</Button>
                    </Box>
                </Grid>
            </Alert>
        </Dialog>
    );
}

export function SignUp_Info_Dialog({ open, onClose, info }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <Alert severity="warning">
                <AlertTitle>Information</AlertTitle>
                <Grid container p={5} justifyContent="center" alignItems="center"
                    direction="column" p={0}>
                    <Typography variant="body1">{info}</Typography>
                    <Box mt={3}>
                        <Button variant="contained" onClick={onClose}>Close</Button>
                    </Box>
                </Grid>
            </Alert>
        </Dialog>
    );
}
