import './Center.css';

function Center({ children, className }) {
    let classes = className ? "Center " + className : "Center";
    return (
        <div className={classes}>{children}</div>
    )
}

export default Center;
