import './TitleText.css';

function TitleText({ level, children, className }) {
    let classes = className ? "TitleText " + className : "TitleText";

    if(level==="3") {
        <h3 className={classes + ' TitleText_level3'}>{children}</h3>
    } else if(level==="2") {
        <h2 className={classes + ' TitleText_level2'}>{children}</h2>
    }
    // Default, level = 1
    return (
        <h1 className={classes + ' TitleText_level1'}>{children}</h1>
    );
}

export default TitleText;
