import './Login_Page.css';
import Header from '../../Components/Header/Header';
import Login_Form from '../../Components/Login/Login_Form';
import Footer from '../../Components/Footer/Footer';
import V_Spacer from '../../Helpers/Spacer/V_Spacer';
import { Login_Error_Dialog, Login_Processing_Dialog } from '../../Components/Login/Login_Dialogs';
import config from '../../config.json';

import { useState, useEffect } from 'react';

// import firebase from "firebase";
import * as Realm from 'realm-web';
let realmApp = new Realm.App({ id:  config.Realm.APP_ID});


function Login_Page() {
    // const firebaseApp = firebase.apps[0];

    let [ formData, setFormData ] = useState({});
    /*
        status values = [IDLE, LOADING, SUCCESS, ERROR, INFO]
    */
    let [ status, setStatus ] = useState("IDLE");
    let [ error, setError ] = useState(null);
    // let [ info, setInfo ] = useState(null);

    useEffect(() => {
        // We won't do anything, formData is empty(could be the first render),
        // Also, when status is not IDLE, it means, it is in LOADING, or SUCCESS/ERROR state
        // So we don't do anything these scenarios
        if(status !== "IDLE" || Object.keys(formData).length === 0) { return; }

        // Login User
        async function authenticate() {
            let { email, password } = formData;
            const credentials = Realm.Credentials.emailPassword(email, password);
            try {
                // Authenticate the user
                const user = await realmApp.logIn(credentials);
                // `App.currentUser` updates to match the logged in user
                if(user.id === realmApp.currentUser.id) {
                    console.log(user);
                } else {
                    throw new Error("Login Error");
                }
            } catch(err) {
                if (err instanceof Realm.MongoDBRealmError) {
                    const { error, statusCode } = err;
                    const errorType = error || statusCode;
                    switch (errorType) {
                        case "invalid username/password":
                            setError("Invalid Email / Password");
                            setStatus("ERROR");
                            break;
                        default:
                            console.log(errorType);
                            setError("Error occurred, could not login user");
                            setStatus("ERROR");
                    }
                } else {
                    console.log(err);
                    setError("Error occurred, could not login user");
                    setStatus("ERROR");
                }
            }
        }
        setStatus("LOADING");
        authenticate();
    }, [formData]);

    return (
        <div className='Login_Page'>
            <Header />
            <V_Spacer /><V_Spacer />
            <div className="Login_Page_content">
                <Login_Form onTrigger={setFormData} />
            </div>
            <V_Spacer /><V_Spacer />
            <Footer />
            <Login_Processing_Dialog open={status==="LOADING"} />
            <Login_Error_Dialog open={status==="ERROR"} error={error}
                onClose={() => setStatus("IDLE")} />
        </div>
    )
}

export default Login_Page;
