import './TextCenter.css';

function TextCenter({ children, className }) {
    let classes = className ? "TextCenter " + className : "TextCenter";
    return (
        <div className={classes}>{children}</div>
    );
}

export default TextCenter;
