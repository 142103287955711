import './Header.css';
import logo from '../../assets/logo.png';
import H_Spacer from '../../Helpers/Spacer/H_Spacer';
import TitleText from '../../Helpers/Text/TitleText';

function Header() {
    return (
        <div className="Header">
            <div className="Header_center">
                <img src={logo} alt="Logo" />
                <H_Spacer />
                <TitleText>Quantum Maintenance</TitleText>
            </div>
        </div>
    );
}

export default Header;
