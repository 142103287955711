import React from 'react'
import V_Spacer from '../../Helpers/Spacer/V_Spacer';
import TextCenter from '../../Helpers/Text/TextCenter';
import Link from '../../Helpers/Text/Link';

function Footer() {
    return (
        <div className='Footer'>
            <V_Spacer />
            <TextCenter><Link>Terms of Use</Link> | <Link>Privacy Policy</Link></TextCenter>
            <V_Spacer />
            <TextCenter>&copy; Quantum Maintenance</TextCenter>
            <V_Spacer />
        </div>
    )
}

export default Footer;
