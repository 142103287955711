import './SignUp_Page.css';
import SignUp_Form from '../../Components/SignUp/SignUp_Form';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import V_Spacer from '../../Helpers/Spacer/V_Spacer';
import config from '../../config.json';
import Utils from '../../Utils';
import { useEffect, useState } from 'react';

import { SignUp_Success_Dialog, SignUp_Error_Dialog,
    SignUp_Processing_Dialog, SignUp_Info_Dialog } from '../../Components/SignUp/SignUp_Dialogs';

import * as Realm from 'realm-web';
let realmApp = new Realm.App({ id:  config.Realm.APP_ID});

function SignUp_Page() {
    let [ formData, setFormData ] = useState({});
    /*
        status values = [IDLE, LOADING, SUCCESS, ERROR, INFO]
    */
    let [ status, setStatus ] = useState("IDLE");
    let [ error, setError ] = useState(null);
    let [ info, setInfo ] = useState(null);
    let [ sentOn, setSentOn ] = useState(0);

    useEffect(() => {
        // We won't do anything, formData is empty(could be the first render),
        // Also, when status is not IDLE, it means, it is in LOADING, or SUCCESS/ERROR state
        // So we don't do anything these scenarios
        if(status !== "IDLE" || Object.keys(formData).length === 0) { return; }

        // Register User, and send Verification Link
        async function authenticate() {
            let { email, password } = formData;
            realmApp.emailPasswordAuth.registerUser({ email, password })
            .then(() => { setError(null); setSentOn(t => Utils.currentTime()); setStatus("SUCCESS") })
            .catch(err => {
                if (err instanceof Realm.MongoDBRealmError) {
                    const { error, statusCode } = err;
                    const errorType = error || statusCode;
                    switch (errorType) {
                        case "name already in use":
                            setInfo("A user with this Email already exists, you can Login or Reset Password");
                            setStatus("INFO");
                            break;
                        default:
                            console.log(errorType);
                            setError("Error occurred while sending Verification Email");
                            setStatus("ERROR");
                    }
                } else {
                    console.log(err);
                    setError("Error occurred while sending Verification Email");
                    setStatus("ERROR");
                }
            });
        }
        setStatus("LOADING");
        authenticate();
    }, [formData]);

    // Resend Verification Link
    const resendConfirmation = (cb) => {
        setStatus("LOADING");
        realmApp.emailPasswordAuth.resendConfirmationEmail({ email: formData.email })
        .then(() => {
            setStatus("SUCCESS");
            let ct = Utils.currentTime();
            setSentOn(ct);
            if(cb) {cb(ct);}
        })
        .catch(error => {
            console.log(error);
            setError("Error occurred while resending Verification Email");
            setStatus("ERROR");
        });
    }

    return (
        <div className="SignUp_Page">
            <Header />
            <V_Spacer /><V_Spacer />
            <div className="SignUp_Page_content">
                <SignUp_Form onTrigger={setFormData} />
            </div>
            <V_Spacer /><V_Spacer />
            <Footer />
            <SignUp_Success_Dialog open={status==="SUCCESS"} onClose={() => setStatus("IDLE")}
                email={formData.email} sentOn={sentOn} resendConfirmation={resendConfirmation} />
            <SignUp_Processing_Dialog open={status==="LOADING"} />
            <SignUp_Error_Dialog open={status==="ERROR"}
                onClose={() => setStatus("IDLE")} error={error} />
            <SignUp_Info_Dialog open={status==="INFO"}
                onClose={() => setStatus("IDLE")} info={info} />
        </div>
    );
}

export default SignUp_Page;
