import './Plan_Form.css';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FilledInput from '@mui/material/FilledInput';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useState } from 'react';
import React from 'react';

import H_Line from '../../Helpers/Line/H_Line';

function Plan_Form() {
    const [ usersCount, setUsersCount ] = useState(1);

    return (
    <form className='Plan_Form'>
        <Typography variant='h6'>
            Number of users :
        </Typography>
        <Slider aria-label="Default" valueLabelDisplay="auto" min={1} max={250}
            value={usersCount} onChange={e => setUsersCount(e.target.value)} />
        <Grid container direction='row' style={{width: '100%'}}
            alignItems='center' justifyContent='center'>
            <Grid item>
                <Button style={{fontSize: '36px', color: 'black'}}
                    onClick={() => setUsersCount(c => c <= 1 ? 1 : c - 1)}>-</Button>
            </Grid>
            <Grid item>
                <FormControl sx={{ m: 1, px: 1, width: (String(usersCount).length + 6) + 'ch' }} variant="filled">
                    <Input value={usersCount}
                        onChange={e => setUsersCount(e.target.value)}
                        endAdornment={<InputAdornment position="end">users</InputAdornment>}
                    />
                </FormControl>
            </Grid>
            <Grid item>
                <Button style={{fontSize: '36px', color: 'black'}}
                    onClick={() => setUsersCount(c => c + 1)}>+</Button>
            </Grid>
        </Grid>
        <FormControl style={{ width: '100%' }}>
            <FormLabel style={{ marginBottom: '1em', marginTop: '1em', fontWeight: 'bold' }}>Billing Period</FormLabel>
            <RadioGroup row defaultValue='monthly' style={{ marginLeft: '12px' }}>
                <FormControlLabel value="monthly" control={<Radio />} label="Monthly"
                    style={{ border: '2px solid cornflowerblue', paddingRight: '1em', borderRadius: '2px',
                            backgroundColor: '#eaeaea'}} />
            </RadioGroup>
        </FormControl>
        <FormControl>
            <FormLabel style={{ marginBottom: '1em', marginTop: '1em', fontWeight: 'bold' }}>Plan Fee</FormLabel>
            <RadioGroup row defaultValue='plan1' style={{ marginLeft: '12px' }}>
                <FormControlLabel value="plan1" control={<Radio />} label="$20 per user/month"
                    style={{ border: '2px solid cornflowerblue', paddingRight: '1em', borderRadius: '2px',
                            backgroundColor: '#eaeaea'}} />
            </RadioGroup>
        </FormControl>
        <Grid container direction='column' mt={2}>
            <Typography variant='h6'>Summary</Typography>
            <span style={{width: 'fit-content', backgroundColor: '#eaeaea', padding: '1em' }}>
                <Typography component='span' variant='body1'>Number of users : {usersCount}</Typography>
                <br />
                <span style={{display: 'inline-block', width: '100%', paddingBottom: '0.5em', paddingTop: '0.5em'}}>
                    <hr style={{ width: '60%', border: 0, borderBottom: '1px solid black' }} />
                </span>
                <span style={{display: 'inline-block', width: '100%', textAlign: 'center'}}>
                    <Typography component='span' style={{fontWeight: 'bold'}} variant='h5'>Total: ${20 * usersCount}</Typography>
                </span>
            </span>
        </Grid>
        <Grid container mt={6} justifyContent='center' alignItems='center'>
            <Button variant='contained'>Confirm</Button>
        </Grid>
    </form>
  );
}

export default Plan_Form;
