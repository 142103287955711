import './Home_Page.css';
import config from '../../config.json';

import * as Realm from 'realm-web';
let realmApp = new Realm.App({ id:  config.Realm.APP_ID});

function Home_Page() {
    if(!realmApp.currentUser) {
        window.location.href = "/login";
    }

    return (
        <div className="Home_Page">
            HomePage
        </div>
    );
}

export default Home_Page;
