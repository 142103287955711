import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import H_Spacer from '../../Helpers/Spacer/H_Spacer';

import React from 'react';


function DashboardAppBar({ toggleDrawer }) {
    return (
        <AppBar variant="dense" position="absolute" sx={{backgroundColor: 'transparent'}}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '1em'
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, color: 'black', textAlign: 'center' }}
            >
              Dashboard
            </Typography>
            <IconButton color="inherit" sx={{color: 'black'}}>
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton color="inherit" sx={{color: 'black'}}>
              <Badge color="secondary">
                <AccountCircleIcon />
              </Badge>
            </IconButton>
            <H_Spacer style={{ width: '0.5em' }}/>
            <Button variant="outlined">LOG OUT</Button>
          </Toolbar>
        </AppBar>
    )
}

export default DashboardAppBar;
