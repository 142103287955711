import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import React from 'react'

function DashboardContent() {
    return (
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar variant="dense" />
          <Container sx={{ maxWidth: '100% !important' }}>
            Content
          </Container>
        </Box>
    )
}

export default DashboardContent;
