import TitleText from '../../Helpers/Text/TitleText';
import TextCenter from '../../Helpers/Text/TextCenter';
import Link from '../../Helpers/Text/Link';
import H_Line from '../../Helpers/Line/H_Line';
import Center from '../../Helpers/Center';
import Utils from '../../Utils';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { useState } from 'react';

function Login_Form({ onTrigger }) {
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ errors, setErrors ] = useState({});

    const validateInputs = () => {
        let newErrors = {};
        // email
        if(email.length !== 0) {
            if(!Utils.validMail(email)) {
                newErrors.email = "Invalid Email";
            } else if(email.length > 255) {
                newErrors.email = "Max size: 255 characters";
            }
        }
        // password
        if(password.length !== 0 && (password.length < 8 || password.length > 128)) {
            newErrors.password = "Expected size between 6 to 128 characters";
        }

        if(Object.keys(newErrors).length == 0) {
            if(Object.keys(errors).length !== 0) {
                setErrors(newErrors);
            }
            return true;
        } else {
            setErrors(newErrors);
            return false;
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        if(!validateInputs()) {
            return;
        }

        onTrigger({
            email, password
        });
    }

    return (
        <form className='Login_Form' onSubmit={onSubmit}
            style={{maxWidth: '90vw', width: 450}}>
            <TextCenter><TitleText level="2">Log in</TitleText></TextCenter>
            <Grid container direction="column" my={3} spacing={1.5}>
                <Grid item>
                    <TextField required fullWidth variant="standard" label="Email" type="email"
                        value={email} onChange={e => setEmail(e.target.value)}
                        error={"email" in errors} helperText={errors.email || null} />
                </Grid>
                <Grid item>
                    <TextField required fullWidth variant="standard" label="Password" type="password"
                        value={password} onChange={e => setPassword(e.target.value)}
                        error={"password" in errors} helperText={errors.password || null} />
                </Grid>
            </Grid>
            <Grid container direction="column" my={4} spacing={2} >
                <Grid item>
                    <Button disableElevation fullWidth style={{backgroundColor: '#003366', borderRadius: 0}}
                        variant='contained' type='submit'>Submit</Button>
                </Grid>
                <Grid item>
                    <TextCenter><Link href="#">Forgot Password?</Link></TextCenter>
                </Grid>
                <Grid item>
                    <Center><H_Line /></Center>
                </Grid>
                <Grid item>
                    <Center>
                        <Link href="/signup">
                            <Button variant="contained" style={{borderRadius: 0}} className="SignUp_Button">Sign Up</Button>
                        </Link>
                    </Center>
                </Grid>
            </Grid>
        </form>
    )
}

export default Login_Form;
