import './InventoryManager.css';
import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import AppBar from './DashboardAppBar';
import DashboardDrawer from './DashboardDrawer';
import DashboardContent from './DashboardContent';
import { light } from '@mui/material/styles/createPalette';



/*
  Dashboard Components -
    ______________________
    | AppBar              |
    |_____________________|
    | D |                 |
    | r |    Dashboard    |
    | a |     Content     |
    | w |      (Box)      |
    | e |                 |
    | r |                 |
    |___|_________________|

    Note: The `Drawer` component is also called `DashboardDrawer`
    The `DashboardContent` component is enclosed in a `Box` component
*/

const lightTheme = createTheme({
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          // Match 24px = 3 * 2 + 1.125 * 16
          boxSizing: 'content-box',
          padding: 3,
          fontSize: '5.125rem',
          color: '#fff'
        },
      },
    },
  },
});

function InventoryManager() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Box sx={{ display: 'flex' }} className="InventoryManager">
        <CssBaseline />
        <AppBar toggleDrawer={toggleDrawer} />
        <DashboardDrawer open={open} setOpen={setOpen} />
        <DashboardContent />
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  return <InventoryManager />;
}
