import TitleText from '../../Helpers/Text/TitleText';
import TextCenter from '../../Helpers/Text/TextCenter';
import Link from '../../Helpers/Text/Link';

import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Utils from '../../Utils';

import { useState } from 'react';

function SignUp_Form({ onTrigger }) {

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("+1 ");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    // const [errors, setErrors] = useState({});

    const validateInputs = () => {
        let newErrors = {};
        // firstName
        if(firstName.length > 255) {
            newErrors.firstName = "Max size: 255 characters";
        }
        // lastName
        if(lastName.length > 255) {
            newErrors.lastName = "Max size: 255 characters";
        }
        // companyName
        if(companyName.length > 255) {
            newErrors.companyName = "Max size: 255 characters";
        }
        // email
        if(email.length !== 0) {
            if(!Utils.validMail(email)) {
                newErrors.email = "Invalid Email";
            } else if(email.length > 255) {
                newErrors.email = "Max size: 255 characters";
            }
        }
        // mobileNumber
        if(mobileNumber.length !== 0) {
            if(mobileNumber.length > 100) {
                newErrors.mobileNumber = "Max size: 100 characters";
            } else if(!Utils.validMobile(mobileNumber)) {
                newErrors.mobileNumber = "Invalid Mobile Number";
            }
        }
        // password
        if(password.length !== 0 && (password.length < 8 || password.length > 128)) {
            newErrors.password = "Expected size between 6 to 128 characters";
        }

        if(confirmPassword != password) {
            newErrors.confirmPassword = "should be same as Password";
        }
        
        if(Object.keys(newErrors).length == 0) {
            if(Object.keys(errors).length !== 0) {
                setErrors(newErrors);
            }
            return true;
        } else {
            setErrors(newErrors);
            return false;
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        if(!validateInputs()) {
            return;
        }

        onTrigger({
            firstName, lastName, companyName, email, mobileNumber, password
        });
    };

    return (
        <form className='SignUp_Form' onSubmit={onSubmit}
            style={{maxWidth: '90vw', width: 450}}>
            <TextCenter><TitleText level="2">Sign Up</TitleText></TextCenter>
            <Grid container direction="row" spacing={1} my={5}>
                <Grid item xs={12}>
                    <Grid container spacing={2} direction="row">
                        <Grid item xs={6}>
                            <TextField required fullWidth label="First Name" variant="standard" value={firstName}
                                onChange={e => setFirstName(e.target.value)}
                                error={"firstName" in errors} helperText={errors.firstName || null} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField required fullWidth label="Last Name" variant="standard" value={lastName}
                                onChange={e => setLastName(e.target.value)}
                                error={"lastName" in errors} helperText={errors.lastName || null} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField required fullWidth label="Company Name" variant="standard" value={companyName}
                        onChange={e => setCompanyName(e.target.value)}
                        error={"companyName" in errors} helperText={errors.companyName || null} />
                </Grid>
                <Grid item xs={12}>
                    <TextField required fullWidth label="Email" type="email" variant="standard"
                        value={email} onChange={e => setEmail(e.target.value)}
                        error={"email" in errors} helperText={errors.email || null} />
                </Grid>
                <Grid item xs={12}>
                    <TextField required fullWidth label="Mobile Number" type="tel" variant="standard"
                        value={mobileNumber} onChange={e => setMobileNumber(e.target.value)}
                        error={"mobileNumber" in errors} helperText={errors.mobileNumber || null} />
                </Grid>
                <Grid item xs={12}>
                    <TextField required fullWidth label="Password" type="password" variant="standard"
                        value={password} onChange={e => setPassword(e.target.value)}
                        error={"password" in errors} helperText={errors.password || null} />
                </Grid>
                <Grid item xs={12}>
                    <TextField required fullWidth label="Confirm Password" type="password" variant="standard"
                        value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                        error={"confirmPassword" in errors} helperText={errors.confirmPassword || null} />
                </Grid>
            </Grid>
            <Box mb={3}>
                <Button fullWidth style={{backgroundColor: '#003366', borderRadius: 0}}
                    variant='contained' type='submit'>Submit</Button>
            </Box>
            <TextCenter>Already a member? <Link href="/login">Log in</Link></TextCenter>
        </form>
    );
}

export default SignUp_Form;
